import { useEffect, useState } from "react"
import { Buffer } from 'buffer';
import { Button, Paper, TextField, Typography } from "@mui/material";
import Company from "../screens/company";
import { useSnackbar } from "notistack";


async function login(basicAuth: string): Promise<boolean> {
    return fetch(`${process.env.REACT_APP_HOST}/service/business`, {
        headers: {
            'Authorization': basicAuth
        }
    }).then((response) => {
        localStorage.setItem('token', basicAuth);
        return response.status !== 401;
    })
}

const Session = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [basicAuth, setBasicAuth] = useState<string | null>(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        setBasicAuth(`Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`);
    }, [password, username]);


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            setBasicAuth(token);
        }
    }, []);

    if (isAuthenticated && basicAuth) {
        return <Company auth={basicAuth} />
    }

    return <div style={{ display: 'flex', justifyContent: 'center', flex: 1, alignContent: 'center' }}>
        <Paper elevation={3} style={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontWeight: 'lighter', fontSize: '1.5em', padding: 10, marginBottom: 10 }}>Cadastro de Empresas</Typography>
            <TextField style={{ padding: 10 }}
                label="Id"
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField type={"password"}
                label="Senha"
                style={{ padding: 10 }}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="outlined"
                disabled={!basicAuth}
                onClick={() => {
                    if (basicAuth) {
                        login(basicAuth).then((ok) => {
                            if (ok) {
                                snackbar.enqueueSnackbar('Olá, vamos lá :D', { variant: 'success' });
                            } else {

                                snackbar.enqueueSnackbar('Poxa vida, login incorreto :(', { variant: 'error' });
                            }
                            setIsAuthenticated(ok);
                        })
                    }
                }}
                style={{ margin: 10 }}
            >
                Login
            </Button>
        </Paper>
    </div>

}

export default Session;