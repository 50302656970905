import { SnackbarProvider } from 'notistack';
import React from 'react';
import './App.css';
import Session from './components/session';

function App() {
  const hasToken = Boolean(localStorage.getItem('token'));
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <div style={{ backgroundColor: '#cf3e38', widows: '100vw', height: '5%', marginBottom: 30, padding: 10, display: 'flex', flex: 1 }}>
        <img src="https://poshcash.com.br/_next/image?url=%2Flogo_posh.png&w=256&q=75" height={'100%'} alt="logotipo da poshcash" />
        {hasToken && <span
          onClick={() => {
            localStorage.removeItem('token');
            window.location.reload();
          }}
          style={{ color: 'white', flex: 1, marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', verticalAlign: 'center', cursor: 'pointer', padding: 5 }}>
          Sair
        </span>}
      </div>
      <Session />
    </div>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (<SnackbarProvider autoHideDuration={5000}> <App /></SnackbarProvider >)
}
