import { Autocomplete, Button, createFilterOptions, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { closestMatch } from 'closest-match';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Map } from 'leaflet';
import data from './domain/data.json';
interface CompanyData {
    "CNPJ_CPF": string;
    "Razão Social": string;
    "Endereço": string;
    "Cidade": string;
    "UF": string;
}

export interface City {
    id: string;
    name: string;
    code: string;
    state_id: string;
}

export interface State {
    id: string;
    name: string;
    code: string;
    cities: City[];
}


export interface Address {
    description: string;
    latitude: number;
    longitude: number;
    city_id: string;
    city?: City;
    neighborhood: string;
    zip_code?: string;
}

export interface CompanyDTO {
    fantasy_name: string;
    name: string;
    corporate_name: string;
    document: string;
    addresses: Address[];
}

async function fetchStates(): Promise<State[]> {
    return fetch(`${process.env.REACT_APP_HOST}/rest/state?scopes=["withCities"]`).then(e => e.json());
}

async function getCompany(auth: string, cnpj: string): Promise<CompanyDTO | null> {
    return fetch(`${process.env.REACT_APP_HOST}/service/business/companies/cnpj/${cnpj}`, {
        headers: {
            'Authorization': auth
        }
    }).then(e => e.json())
}

async function saveCompany(auth: string, company: CompanyDTO) {
    return fetch(`${process.env.REACT_APP_HOST}/service/business/companies`, {
        method: 'POST',
        body: JSON.stringify(company).toString(),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth
        }
    }).then(async response => {
        if (response.ok) {
            if (response.status === 202) {
                return 'Sucesso, empresa alterada';
            }
            return 'Sucesso, empresa cadastrada.'
        } else {
            try {
                const error = await response.json();
                throw new Error(error.message);
            } catch (err) {
                throw new Error((err as Error).message);
            }
        }

    });
}


const nilSTATE = { id: '', code: '', cities: [], name: '' }
const nilCITY = { id: '', code: '', name: '', state_id: '' };

const defaultFilterOptions = createFilterOptions<{ id: string, label: string }>();

const Company: React.FC<{ auth: string }> = ({ auth }) => {
    const [companyList, setCompanyList] = useState<{ id: string, label: string, data: CompanyData }[]>([]);
    const [mappedCompanies, setMappedCompanies] = useState<Record<string, { id: string, label: string, data: CompanyData }>>({});
    const [generalCityList, setGeneralCityList] = useState<Record<string, [City, State]>>({});
    const [stateList, setStateList] = useState<State[]>([]);
    const [cityList, setCityList] = useState<City[]>([]);
    const [state, setState] = useState<State>(nilSTATE);
    const [city, setCity] = useState<City>(nilCITY);
    const [map, setMap] = useState<Map | null>(null);


    const [cnpj, setCNPJ] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [fantasyName, setFantasyName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [neighborhood, setNeighborhood] = useState<string>('');
    const [postcode, setPostcode] = useState<string>('');

    const [coordinates, setCoordinates] = useState<[number, number]>([-3.0839797,-60.0707565]);

    const [cnpjAlreadyUsed, setCNPJAlreadyUsed] = useState(false);

    const [canSave, setCanSave] = useState<boolean>(Boolean(cnpj && name && address && neighborhood && coordinates && city && state));

    const snackbar = useSnackbar();

    function changeAddress(e: { city: string, state: string, street: string, neighborhood: string, lat: number, lng: number, postcode: string } | null) {
        if (e) {
            const [responseState, responseCity, responseAddress, responseNeighborhood] = [e.state, e.city, e.street, e.neighborhood];
            const closestState = closestMatch(responseState || state.name, stateList.map(e => e.name));
            const dueState = stateList.find(e => e.name === closestState);
            const closestCity = closestMatch(responseCity || city.name, (dueState?.cities || []).map(e => e.name));
            const dueCity = dueState?.cities.find(e => e.name === closestCity);

            setState(dueState || nilSTATE);
            setCity(dueCity || nilCITY);
            setAddress(responseAddress);
            setNeighborhood(responseNeighborhood);
            setPostcode(e.postcode);
            setCoordinates([e.lat, e.lng]);
        }
    }


    useEffect(() => {
        async function run() {
            await fetchStates().then((states) => {
                setGeneralCityList(
                    Object.fromEntries(states.flatMap(state => state.cities.map(city => ([city.id, [city, state]]))))
                )
                setStateList(states.sort((a: State, b: State) => a.code.localeCompare(b.code)))
            });

            const companyRecords = data.reduce((acc, cur) => {
                acc[cur.CNPJ_CPF] = { id: cur.CNPJ_CPF, label: `${cur.CNPJ_CPF} - ${cur['Razão Social']}`, data: cur };
                return acc;
            }, {} as Record<string, { id: string, label: string, data: CompanyData }>);

            setMappedCompanies(companyRecords);
            setCompanyList(Object.values(companyRecords));
        }
        run();
    }, []);

    useEffect(() => {
        if (cnpj) {
            getCompany(auth, cnpj).then((company) => {
                if (company) {
                    if (cnpj === company?.document) {
                        setCNPJAlreadyUsed(true);
                        setName(company.corporate_name);
                        setFantasyName(company.fantasy_name);
                        if (company.addresses && company.addresses.length > 0) {
                            const [address] = company.addresses;
                            setCity(generalCityList[address.city?.id as string][0])
                            setState(generalCityList[address.city?.id as string][1])
                            setCoordinates([address.latitude, address.longitude])
                            setAddress(address.description || '');
                            setNeighborhood(address.neighborhood || '');
                            setPostcode(address.zip_code || '')
                        } else {
                            setCity(nilCITY);
                            setState(nilSTATE)
                            setAddress('');
                            setNeighborhood('');
                            setPostcode('')
                        }
                    }
                } else {
                    setCNPJAlreadyUsed(false);
                    if (cnpj in mappedCompanies) {
                        const company = mappedCompanies[cnpj].data;
                        const curState = stateList.find(e => e.code === company.UF) || nilSTATE;
                        const curCity = curState.cities.find(e => e.name === company.Cidade) || nilCITY;

                        setName(company['Razão Social']);
                        setAddress(company['Endereço']);
                        setState(curState);
                        setCity(curCity);
                    } else {
                        setName('');
                        setAddress('');
                        setState(nilSTATE);
                        setCity(nilCITY);
                    }
                    setNeighborhood('');
                    setPostcode('');
                }
            });
        } else {
            setCNPJAlreadyUsed(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cnpj]);

    useEffect(() => {
        setCanSave(Boolean(cnpj && name && address && neighborhood && coordinates && city && state));
    }, [address, city, cnpj, coordinates, name, neighborhood, state]);

    useEffect(() => {
        if (state) {
            setCityList(state.cities.sort((a: City, b: City) => a.name.localeCompare(b.name)));
        } else {
            setCityList([]);
        }
    }, [state]);

    const updateMap = () => {
        if (map && coordinates) {
            map.flyTo(coordinates);
        }
    }

    useEffect(() => {
        updateMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coordinates]);


    return (
        <div style={{ padding: '0.5%' }}>
            <Grid container spacing={1}>
                <Grid item xs={0.75} />
                <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        disablePortal
                        filterOptions={(options, state) => defaultFilterOptions(options, state).slice(0, cnpj.length > 0 ? 2 : 0)}
                        id="combo-box-demo"
                        options={[{ id: cnpj, label: cnpj }, ...companyList]}
                        onChange={(_, value) => setCNPJ((value?.id) || '')}
                        fullWidth
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                id="outlined-helperText"
                                label="CNPJ"
                                defaultValue=""
                                fullWidth
                                helperText="CNPJ da empresa"
                                onChange={(e) => setCNPJ(e.target.value)}
                            />
                        }
                    />
                    {cnpjAlreadyUsed && <Typography style={{ color: '#dd0000' }}>Esse CNPJ já está cadastrado</Typography>}
                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={3.5}>
                    <TextField
                        id="outlined-helperText"
                        fullWidth
                        required
                        label="Razão Social"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        defaultValue=""
                        helperText="Social da empresa"
                    />
                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={3.5}>
                    <TextField
                        id="outlined-helperText"
                        fullWidth
                        required
                        label="Nome Fantasia"
                        value={fantasyName}
                        onChange={(e) => setFantasyName(e.target.value)}
                        defaultValue=""
                        helperText="Nome fantasia da empresa (aparecerá para os usuários)"
                    />
                </Grid>
                <Grid item xs={0.75} />
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: 30 }}>
                <Grid item xs={0.75} />
                <Grid item xs={1.0}>
                    <FormControl fullWidth>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            displayEmpty
                            required
                            label="Estado"
                            value={state?.id}
                            onChange={e => {
                                setState(stateList.find(state => state.id === e.target.value) || nilSTATE);
                            }}
                        >
                            {stateList.map(state => (<MenuItem value={state.id}>{state.code}</MenuItem>))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Cidade</InputLabel>
                        <Select
                            displayEmpty
                            required
                            label="Cidade"
                            value={city?.id}
                            onChange={e => {
                                setCity(cityList.find(city => city.id === e.target.value) || nilCITY);
                            }}
                        >
                            {cityList.map(city => (<MenuItem value={city.id}>{city.name}</MenuItem>))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={2.5}>
                    <TextField
                        id="outlined-helperText"
                        fullWidth
                        required
                        label="Endereço"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        defaultValue=""
                        helperText="Endereço da empresa"
                    />
                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={1.5}>
                    <TextField
                        id="outlined-helperText"
                        fullWidth
                        label="CEP"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                        defaultValue=""
                        helperText="CEP da empresa"
                    />
                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={1.5}>
                    <TextField
                        id="outlined-helperText"
                        required
                        fullWidth
                        label="Bairro"
                        value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)}
                        defaultValue=""
                        helperText="Bairro da empresa"
                    />
                </Grid>
                <Grid item xs={0.75} />
            </Grid >
            <Grid container spacing={1} style={{ paddingTop: 30 }}>
                <Grid item xs={0.75}/>
                <Grid item xs={5}>
                    <TextField
                        id="outlined-helperText"
                        required
                        fullWidth
                        type="number"
                        label="Latitude"
                        defaultValue={coordinates[0]}
                        value={coordinates[0]}
                        onChange={(e) => setCoordinates([parseFloat(e.target.value),coordinates[1]])}
                        helperText="Latitude"
                    />
                </Grid>
                <Grid item xs={0.5} />
                <Grid item xs={5}>
                    <TextField
                        id="outlined-helperText"
                        required
                        fullWidth
                        type="number"
                        label="Longitude"
                        defaultValue={coordinates[0]}
                        value={coordinates[1]}
                        onChange={(e) => setCoordinates([coordinates[0],parseFloat(e.target.value)])}
                        helperText="Longitude"
                    />
                </Grid>
                <Grid item xs={0.75}/>
            </Grid>

            <div style={{ border: '1px solid gray', borderRadius: 5, padding: 2, backgroundColor: 'gray', marginTop: 30, display: 'flex', flexDirection: 'column' }}>
            <MapContainer center={coordinates || [0,0]} zoom={13} style={{ width: '100%', height: '350px' }} whenCreated={ref => setMap(ref)}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {coordinates && <Marker position={coordinates} draggable eventHandlers={{
                dragend: async (event) => {
                    const latlng = event.target.getLatLng() as { lat: number; lng: number };
                    setCoordinates([latlng.lat, latlng.lng]);
                }
            }}>
            </Marker>}
        </MapContainer >

            </div>

            <Grid
                container
                style={{ paddingTop: 30 }}
            >
                <Grid item xs={12}>
                    <Button variant='contained' disabled={!canSave}
                        style={{ height: 50 }}
                        fullWidth
                        onClick={() => {
                            if (coordinates) {
                                console.log(coordinates);
                                saveCompany(auth, {
                                    name: fantasyName,
                                    fantasy_name: fantasyName,
                                    corporate_name: name,
                                    document: cnpj,
                                    addresses: [
                                        {
                                            city_id: city.id,
                                            description: address,
                                            neighborhood,
                                            latitude: coordinates[0],
                                            longitude: coordinates[1],
                                            zip_code: postcode,
                                        }
                                    ]
                                }).then(message => {
                                    snackbar.enqueueSnackbar(message, { variant: 'success' });
                                }).catch(err => {
                                    snackbar.enqueueSnackbar(err.message, { variant: 'error' });
                                });
                            }
                        }}
                    >Salvar</Button>
                </Grid>
            </Grid>

        </div >
    )
}


export default Company;
